/** @format */
import { MAX_FILTER_PRICE, MIN_FILTER_PRICE } from '../@ducks/listing/constants';
import { TLocale } from '../utils/gatsby/types';

export type TApiSearchParams = {
  language?: TLocale;
  orderBy?: string; // TODO to be removed
  query: string;
  checkInDate?: string;
  positions?: { latitude: number; longitude: number }[];
  pageIndex?: number;
  districtIds?: string[];
  sorting?: {
    orderBy: string;
    ascOrder: boolean;
  };
  filters?: {
    airConditioning: boolean;
    balcony: boolean;
    bikeParking: boolean;
    clothesDryer: boolean;
    concierge: boolean;
    internalSide: boolean;
    lowerFloor: boolean;
    topFloor: boolean;
    privateBathroom: boolean;
    elevator: boolean;
    colivingBuilding: boolean;
    singleRoom: boolean;
    oneBedroomApartment: boolean;
    entireStudio: boolean;
    tenantRestrictionType?: 'NoRestriction' | 'WomenOnly';
    twoOccupantsAllowed?: boolean;
    monthlyPrice: {
      min: number;
      max: number;
    };
  };
  areas?: { max_latitude: number; min_latitude: number; max_longitude: number; min_longitude: number }[];
  maxItemsPerPage?: number;
};

export const DEFAULT_FILTERS_API_SEARCH: TApiSearchParams['filters'] = {
  airConditioning: false,
  balcony: false,
  bikeParking: false,
  clothesDryer: false,
  concierge: false,
  internalSide: false,
  lowerFloor: false,
  topFloor: false,
  privateBathroom: false,
  elevator: false,
  colivingBuilding: false,
  singleRoom: false,
  entireStudio: false,
  oneBedroomApartment: false,
  monthlyPrice: {
    min: MIN_FILTER_PRICE,
    max: MAX_FILTER_PRICE,
  },
};
