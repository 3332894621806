/** @format */

import { BUILD_LOCALES } from '../../utils/gatsby/constants';
import { TLocale, TLocalesObject } from '../../utils/gatsby/types';

export function reduceLocales<T>(value: (locale: TLocale) => T, locales?: TLocale[]): TLocalesObject<T> {
  return (locales ? locales : BUILD_LOCALES).reduce<TLocalesObject<T>>((acc, curr) => {
    acc[curr] = value(curr);
    return acc;
  }, {} as TLocalesObject<T>);
}
