/** @format */

import React, { memo, ReactNode } from 'react';

import { ReduxProvider } from './redux/Provider';
import { ThemeProvider } from './theme/Provider';

interface Props {
  children: ReactNode;
}

export const Providers = memo(({ children }: Props) => (
  <ThemeProvider>
    <ReduxProvider>{children}</ReduxProvider>
  </ThemeProvider>
));
